function FetchData(type, id) {
    GetData(`${process.env.REACT_APP_API_URL}/${type}${id ? '/' + id : ''}`)
        .then(response => {
            console.log(response)
            return response
        })
        .catch(error => {
            console.error(error);
        })
}

async function GetData(type, id) {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/${type}${id ? '/' + id : ''}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        },
        credentials: 'include',
        redirect: 'manual',
        referrerPolicy: 'no-referrer',
    });
    if (response.status === 401 && window.location.pathname !== "/") {
        window.location.href = "/"
    }
    else {
        return response.json(); // parses JSON response into native JavaScript objects
    }
}

async function PostData(type, data = {}) {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/${type}`, {
        method: 'POST',
        cache: 'no-cache',
        headers: {
            'Content-Type': 'application/json'
        },
        credentials: 'include',
        redirect: 'manual',
        referrerPolicy: 'no-referrer',
        body: JSON.stringify(data)
    });
    if (response.status === 401 && window.location.pathname !== "/") {
        window.location.href = "/"
    }
    else if (response.status === 404) {
        return {message: 'De gebruikersnaam of het wachtwoord is onjuist.'}
    }
    else {
        return response.json(); // parses JSON response into native JavaScript objects
    }
}

export {
    PostData,
    GetData,
    FetchData as default
}