import Login from "./Login";
import Forgot from "./Forgot";
import Reset from "./Reset";
import Dashboard from "./Dashboard";
import Form from "./Form";
import NotFound from "./NotFound";

import {
  Switch,
  Route,
  withRouter
} from "react-router-dom";

function Routes() {
  return (
    <Switch>
      <Route path="/dashboard" component={ Dashboard } />
      <Route path="/form" component={ Form } />
      <Route path="/forgot" component={ Forgot } />
      <Route path="/reset" component={ Reset } />
      <Route exact path="/" component={ Login } />
      <Route component={ NotFound } />
    </Switch>
  )
}

export default withRouter(Routes)