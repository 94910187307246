import React from "react";
import '../Stylesheets/Login.css';
import { Link } from "react-router-dom";
import { PostData } from '../Javascripts/API'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'

class Reset extends React.Component {

  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.state = {
      psw: '',
      confirmpsw: '',
      btnDisabled: true,
      errorMsg: ''
    }
  }

  async handleSubmit(){
    if(!this.state.btnDisabled){
      this.setState({errorMsg: ''})
      if(this.state.psw.length >= 6 && this.state.confirmpsw.length >= 6){
        if(this.state.psw === this.state.confirmpsw){

          var url = window.location.pathname;
          var splitUrl = url.split("/");
          var token = splitUrl[splitUrl.length-1]

          await PostData(`reset/${token}`, {newPassword: this.state.psw, confirmPassword: this.state.confirmpsw})
          .then((data) => {
            if(data.message !== undefined){
              this.setState({errorMsg:data.message})
            }
          })
          .catch((err) => {
              this.setState({errorMsg:'Er is een fout opgetreden: ' + err})
          })
        }
        else{
          this.setState({errorMsg: "De wachtwoorden komen niet overeen."});
        }
      }
      else{
        this.setState({errorMsg: "Uw nieuwe wachtwoord moet minimaal uit 6 tekens bestaan."});
      }
    }
    console.log(this.state.errorMsg)
  }
  

  handleChange(event) {
    var msg = event.target.value
    var psw_new, psw_confirm;
    if(event.target.id === "psw_new"){
      this.setState({ psw: msg });
      psw_new = msg;
      psw_confirm = this.state.confirmpsw;
    } else if(event.target.id === "psw_confirm"){
      this.setState({ confirmpsw: msg });
      psw_new = this.state.psw;
      psw_confirm = msg;
    } 

    if(psw_new.length >= 6 && psw_confirm.length >= 6){
      this.setState({ btnDisabled: false });
    }
    else{
      this.setState({ btnDisabled: true });
    }
  }

    render(){
        return (
            <div className="LoginContainer">
                <img src={"../Images/logo_small.png"} alt={"K&H Logo"}></img>
                <h1>Wachtwoord opnieuw instellen</h1>
                {this.state.errorMsg !== '' ? <p id="error"><span id="errorIcon"><FontAwesomeIcon icon={faExclamationTriangle}/></span>{this.state.errorMsg}</p> : ''}
              <form>
                  <input 
                    type={"password"} 
                    placeholder={"Nieuw wachtwoord"} 
                    required 
                    id="psw_new" 
                    onChange={this.handleChange}
                    value={this.state.psw}
                  />
                  <input  
                    type={"password"} 
                    placeholder={"Bevestig nieuw wachtwoord"} 
                    required id="psw_confirm" 
                    onChange={this.handleChange}
                    value={this.state.confirmpsw}
                  />
                  <p id="ForgotPSW">Uw nieuwe wachtwoord moet minimaal uit <b style={{color:'var(--accent-blue)'}}>6 tekens</b> bestaan.</p>
                  <button className={this.state.btnDisabled ? "LoginButton Disabled" : "LoginButton"} style={{backgroundColor:'var(--accent-blue)',color:'white'}} type={"reset"} onClick={
                      () => this.handleSubmit()
                    }>Bevestigen</button>
                    <Link to="/" className="link" id="BackToLoginLink">Terug naar inloggen</Link>
              </form>
            </div>
        );
    }
  }
  
  export default Reset;
  