import React from "react";
import { Fragment } from 'react';
import Resizer from "react-image-file-resizer";
import '../Stylesheets/Form.css';
import { Link } from "react-router-dom";
import ContentEditable from 'react-contenteditable';
import SignatureCanvas from 'react-signature-canvas';
import Loading from "./Loading";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GetData } from '../Javascripts/API';
import { PostData } from '../Javascripts/API';
import ScrollIntoView from 'react-scroll-into-view';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { 
  faMapMarkerAlt,
  faArrowLeft, 
  faCheck, 
  faTrash, 
  faCaretRight, 
  faCaretDown, 
  faCamera,
  faPlus
} from '@fortawesome/free-solid-svg-icons';

class Form extends React.Component {
  constructor(props){
    super(props);
    this.generateQuestion = this.generateQuestion.bind(this);
    this.assembleQuestion = this.assembleQuestion.bind(this);
    this.createPhotoBox = this.createPhotoBox.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.validateQuestions = this.validateQuestions.bind(this);
    this.storeSignature = this.storeSignature.bind(this);
    this.getLocation = this.getLocation.bind(this);
    this.getTimeStamp = this.getTimeStamp.bind(this);
    this.removeExtraPhoto = this.removeExtraPhoto.bind(this);
    this.updateExtraPhoto = this.updateExtraPhoto.bind(this);
    this.flashQuestion = this.flashQuestion.bind(this);
    this.unfoldQuestion = this.unfoldQuestion.bind(this);
    this.foldQuestions = this.foldQuestions.bind(this);
    this.submitForm = this.submitForm.bind(this);

    this.state = {
      id: this.props.location.state.id,
      data: undefined,
      clicked: [],
      answers: [],
      photos: [],
      extraPhotos: [],
      updatePhotos: {
        description: [],
        remove:[]
      },
      completed: false,
      remaining: undefined,
      remainingUUIDs: [],
      saving: false,
      submitting: false,
      disabledList: [],
      isDisabled: false,
      modal: 'closed',
      lockScroll: true,
      finishedLoading: false,
      devLogs: false,
      styles: [],

      log: []
    };
  }

  async componentDidMount() {
    var data = await GetData('vragenlijsten', this.props.location.state.id).catch((err) => {
        console.error(err)
    });
    this.setState({data: data});
    var answers = this.state.answers;

    function storeAnswer(question){
      if(question["Antwoord"] !== ''){
        if(question["Type"] === 'checkbox'){
          answers[question["UUID_Vraag"]] = question["Antwoord"].split(",");
        }
        else{
          answers[question["UUID_Vraag"]] = question["Antwoord"];
        }

        if(question["Timestamp"] !== ''){
          answers[question["UUID_Vraag"]+"_timestamp"] = question["Timestamp"]
        }
        if(question["SubVragen"] !== undefined && question["SubVragen"].length > 0){
          if((question["Conditioneel"] === "true" && (question["Antwoord"] === "Ja" || question["Antwoord"] === "Anders...")) || (question["Conditioneel"] === "false")){
            question["SubVragen"].forEach(sub => {
              storeAnswer(sub);
            })
          }
        }
        if(question["Antwoord"] === 'Ja' && question["Bepalend"] === "true"){
          isDisabled = true;
          disabledList[question["UUID_Vraag"]] = true;
        }
      }
      else{
        answers[question["UUID_Vraag"]] = undefined;
      } 
    }

    function registerID(question, parent){
      if(parent !== ''){
        parent = "ID_"+parent["UUID_Vraag"];
      }
      styles["ID_"+question["UUID_Vraag"]] = {
        classname: '',
        parent: parent
      }
      if(question["SubVragen"] !== undefined && question["SubVragen"].length > 0){
        question["SubVragen"].forEach(sub => {
          registerID(sub, question)
        })
      }
    }

    var disabledList = this.state.disabledList
    var isDisabled = false;
    var styles = [];
    try{
      data["Vragen"].forEach(question => {
        registerID(question, '')
        storeAnswer(question)
      })
      this.setState({answers: answers});
      this.setState({extraPhotos: this.state.data.ExtraPhotos === undefined ? [] : this.state.data.ExtraPhotos});
      this.setState({disabledList: disabledList});
      this.setState({isDisabled: isDisabled});
      this.setState({styles:styles})
      this.validateQuestions(this.state.data.Vragen);
      this.setState({finishedLoading: true})
    }
    catch(err){
      window.location.href="/dashboard";
    }
    let log = this.state.log;
    Object.keys(this.state.extraPhotos).forEach((key) => {
      log.push({time: new Date(), msg: "Found photo " + key});
    });
    this.setState({log:log});
  }

  handleClick(index, scroll) {
    var enabled = false;
    var questions = this.state.data.Vragen;
    var key = questions.findIndex(x => x["UUID_Vraag"]===index)
    if(key !== -1){
      if(questions[key]["Bepalend"] === "true"){
        enabled = true;
      }
    }
    if(!this.state.isDisabled || enabled){
      let clicked=this.state.clicked;
      if(clicked[index] === undefined){
        clicked[index] = true
      }
      else{
        clicked[index]=!clicked[index]
      }
      this.setState({clicked: clicked}, () => {
        if(scroll === true && clicked[index]){
          window.scrollTo(0,document.body.scrollHeight);
        }
      });
    }
  }

  handleChange(event){
    let id = event.target.id;
    let answers = this.state.answers;
    let questions = this.state.data.Vragen;
    let photos = this.state.photos;
    let answer = '';

    if(event.target.value === undefined){
      answer = event.target.innerText;
    }

    else if(event.target.files !== null){
      if(event.target.files[0] !== undefined){
        const resizeFile = (file) =>
          new Promise((resolve) => {
            var fileType = file.type.split('/')[1]
            Resizer.imageFileResizer(
              file,
              fileType,
              2160,
              2160,
              100,
              0,
              (uri) => {
                resolve(uri);
              },
              "base64"
            );
          });

        const resizeImage = async function(file){
          const answer = await resizeFile(file);
          if(id.split("_")[0] === "extraFoto"){
            photos[id] = answer;
            this.setState({photos:photos});
            let log = this.state.log;
            log.push({time: new Date(), msg: "Adding photo to photos array: " + photos.length});
            this.setState({log:log});
          }
          else{
            answers[id] = answer;
            this.setState({answers:answers});
          }
          this.validateQuestions(this.state.data.Vragen);
        }.bind(this);

        resizeImage(event.target.files[0])   
        .then(() => {
          if(id.split("_")[0] !== "extraFoto"){
            this.getLocation(id)   
          }
          event.target.value = null;
        });
      }
    }
    else{
      answer = event.target.value;
    }

    if(event.target.type === "checkbox"){
      answer = id.split("_")[1];
      id = id.split("_")[0];
      if(answers[id] !== undefined){
        var answerArray = answers[id];
        if(event.target.checked){
          if(!answerArray.includes(answer)){
            answerArray.push(answer);
          }
        }
        else{
          if(answerArray.includes(answer)){
            answerArray.splice(answerArray.indexOf(answer), 1)
          }
        }
        answer = answerArray;
      }
      else{
        answer = [answer]
      }
    }
    else if(event.target.type === "radio"){
      answer = id.split("_")[1];
      id = id.split("_")[0];
    }
    
    if(id.split("_")[0] !== "extraFoto"){
      answers[id] = answer;
      this.setState({answers:answers});
    }
    if(id.split("_")[2] === "omschrijving"){
      answers[id] = answer;
      this.setState({answers:answers});
    }

    var key = questions.findIndex(x => x["UUID_Vraag"]===id)
    if(key !== -1){
      if(questions[key]["Bepalend"] === 'true'){
        var disabledList = this.state.disabledList;
        if(answer === "Ja"){
          disabledList[id] = true
        }
        else{
          disabledList[id] = false
        }
        this.setState({disabledList:disabledList})
        var isDisabled = false;
        Object.keys(disabledList).forEach(key => {
          if(disabledList[key]){
            isDisabled = true;
          }
        })
        if(isDisabled && !this.state.isDisabled){
          this.setState({modal: 'afronden'})
        }
        this.setState({isDisabled:isDisabled})
        if(isDisabled){
          let clicked=this.state.clicked;
          Object.keys(clicked).forEach(key => {
            if(!Object.keys(disabledList).includes(key)){
              clicked[key] = false
            }
          })
          this.setState({clicked: clicked});
        }
      }
    }
    this.validateQuestions(this.state.data.Vragen, isDisabled, answers);
  }

  generateQuestion(item){

    var id, type, answer, subQuestions = undefined;
    var answers = this.state.answers;
  
    if(item["Type"] !== undefined){
      id = item["UUID_Vraag"];
      if(answers[id] !== undefined){
        answer = answers[id];
      }
      type = item["Type"];
    }
    else{
      type = item;
    }

    var question = '';
    switch(type){
      case "radio":
        var isYesNo = false;
        if(item["Options"].includes("Ja") && 
        item["Options"].includes("Nee") &&
        item["Options"].length === 2){
          isYesNo = true;
        }
        question =    <div id={isYesNo?"flexOptions":""}>
        {item["Options"].map(element => {
          return (
            <div className="Option" key={id+'_'+element}>
              <input
                type="radio"
                id={id+'_'+element}
                name={id}
                onChange={this.handleChange}
                defaultChecked={item["Antwoord"] === element ? true : false}
              ></input>
              <label htmlFor={id+'_'+element}>
                <span>{element}</span>
              </label>
            </div>
          );
        })}
      </div>
      break;
      case "checkbox":
        question =      <div>
                      {item["Options"].map(element => {
                        return (
                          <div className="Option" key={id+'_'+element}>
                            <input
                              type="checkbox"
                              id={id+'_'+element}
                              onChange={this.handleChange}
                              defaultChecked={item["Antwoord"].split(",").includes(element) ? true : false}
                            ></input>
                            <label htmlFor={id+'_'+element}>
                              <span>{element}</span>
                            </label>
                          </div>
                        );
                      })}
                    </div>
      break;
      case "number":
        if(item["Max"] === ""){
          question =      <input 
                        type="number"
                        id={id}
                        onBlur={this.handleChange}
                        value={answer === undefined ? '' : answer}
                        placeholder={item["Placeholder"]}
                      />
        }
        else{
          question =  <div>
                        <input 
                          type="range"
                          max={item["Max"]} 
                          min={item["Min"]} 
                          id={id}
                          value={answer === undefined ? 0 : answer}
                          onChange={this.handleChange}
                        />
                        <p id="rangeCurrent" style={{marginLeft:'10px'}}>
                          {answer !== undefined ? 
                          answer : ''}
                        </p>
                        <div className="RangeIndicator">
                          <p>{item["Min"]}</p>
                          <p>{item["Max"]}</p>
                        </div>
                      </div>      
        }
      break;
      case "image":
        var source = ''
        if(item["DocumentBase64"] !== undefined && item["DocumentBase64"] !== ""){
          source = 'data:image;base64,'+item["DocumentBase64"]
        }
        if(answers[item["UUID_Vraag"]] !== undefined){
          source = answers[item["UUID_Vraag"]]
        }  
        question =  <div>
                      {item["DocumentUploaded"] !== 'true' && navigator.geolocation  ? 
                        <label htmlFor={id} className="ImageUpload">
                          <FontAwesomeIcon icon={faCamera} id="icon" style={{marginRight:'5px'}} />Camera openen
                          <input 
                            type="file"
                            id={id}
                            onChange={this.handleChange}
                            accept="image/*"
                            capture
                          />
                        </label> : null
                      }
                      {source !== ''?
                        <img 
                          src={source}
                          alt={item["Vraag"]} 
                          id="mainImage" 
                          className="Image">
                        </img>:null
                      }
                    </div>
      break;
      case "textarea":
        question =      <ContentEditable
                      id={id}
                      html={answer === undefined ? '' : answer}
                      onBlur={this.handleChange}
                      tagName='article'
                      placeholder={item["Placeholder"]}
                    />
      break;
      case "text":
        question =     <input 
                      type={"text"}
                      id={id}
                      placeholder={item["Placeholder"]}
                      onBlur={this.handleChange}
                    />
      break;
      case "canvas":
        let canvasSource = '';
        if(item["DocumentBase64"] !== ""){
          canvasSource = 'data:image;base64,'+item["DocumentBase64"]
        }
        question = 		<div>
                        {item["DocumentBase64"] !== "" ?
                          <img
                          src={canvasSource}
                          style={{width:'300px', height:'170px'}}
                          alt='Handtekening'
                          ></img>
                        :
                        <SignatureCanvas 
                          canvasProps={{
                            width: 300, 
                            height: 170, 
                            className: 'sigCanvas'
                          }} 
                          ref={(ref) => { this.sigPad = ref }}
                          onEnd={() => {
                            this.forceUpdate();
                            let sigField = this.sigPad;
                            if(!sigField.isEmpty()){this.storeSignature(sigField.getCanvas().toDataURL('image/png'),id)}
                          }}
                          velocityFilterWeight={0.2}
                        />
                        }
                        {item["DocumentBase64"] !== "" || (this.state.answers[id] !== "" && this.state.answers[id] !== undefined) ? <div id="clearSig" onClick={() => {item["DocumentBase64"] !== "" ? this.clearSignature(null, id) : this.clearSignature(this.sigPad,id)}}>Opnieuw</div> : ''}
                      </div>
                        
        break;
        case "photo":
          question = this.createPhotoBox();
        break;
      default:
        question =     <input 
                      type={"text"}
                      id={id}
                      placeholder={item["Placeholder"]}
                      onBlur={this.handleChange}
                    />
    }
    
    if(item["Type"] === 'radio' && (answer === "Ja" || answer === "Anders...")){
      subQuestions = true;
    }
    else if(item["Conditioneel"] === "false" || item["Conditioneel"] === ""){
      subQuestions = true;
    }
    else{
      subQuestions = false;
    }
    var subvragen, condition = ''
    var conditionalSubs = false;
    if(item["SubVragen"] !== undefined && item["SubVragen"].length !== 0){
      if(item["SubVragen"].length === 2 && (item["SubVragen"][0]["Conditioneel"].toLowerCase() === "ja" || item["SubVragen"][0]["Conditioneel"].toLowerCase() === "nee")){
        conditionalSubs = true;
        subQuestions = true;
      }
      subvragen = <div id="subQuestions" className={subQuestions.toString()}>
        {item["SubVragen"].map((subItem, index) => {
        if(answers[item["UUID_Vraag"]] !== undefined){
          if(conditionalSubs){
            condition = answers[item["UUID_Vraag"]].toLowerCase()
          }
        }
        return <div 
        key={subItem["UUID_Vraag"]+"_"+index} 
        id={"ID_"+subItem["UUID_Vraag"]} 
        
        className={conditionalSubs && condition !== subItem["Conditioneel"].toLowerCase() ? "false SubContainer" : "SubContainer"}
        >  
                <div className="ScrollHelper" id={"helper_ID_"+subItem["UUID_Vraag"]}></div>

                {subItem["Header"] !== "" ? <h1 id="subTitleSub">{subItem["Header"]}</h1> : null }
                <h1
                  className={this.state.styles["ID_"+subItem["UUID_Vraag"]]!==undefined ?
                  this.state.styles["ID_"+subItem["UUID_Vraag"]]['classname']:null}
                > 
                  {subItem["Vraag"]}{subItem["Verplicht"] === "true" ?<span id="required">*</span>:''}
                </h1>
                {this.generateQuestion(subItem)}
              </div>
      })}
        </div>
    }

    return <div>
      {question}
      {subvragen}
    </div>
  }

  assembleQuestion(item){
    var openState, answer = undefined;
    var id = item['UUID_Vraag'];
    if(item.type !== "image" && item.type !== "canvas"){
      answer = this.state.answers[id]

      if(answer !== undefined){
        if(item["Type"] === 'checkbox'){
          answer = answer.join(", ");
        }
        if(answer.length > 100){
          answer = answer.substring(0,100)+"...";
        }   
      }
    }
    openState = this.state.clicked[id];
    if(openState === undefined){
      openState = false;
    }

    var dropdownQuestion = (
      <div id="dropdownContent" className={item["DocumentUploaded"] !== 'true' ? openState.toString() : 'true'}>
        {this.generateQuestion(item)}
        {item['LocatiePostcode'] !== undefined && item['LocatiePostcode'] !== "" ?
        <div style={{display:"inline-block"}}><a id="locationDiv" target='_blank' rel="noreferrer" href={"http://maps.google.com/?q=" + item['LocatieAdres'] + " " + item['LocatiePostcode'] + " " + item['LocatiePlaats']}>
          <FontAwesomeIcon icon={faMapMarkerAlt} id="icon" style={{marginRight:'5px'}} />
          <div><p>{item['LocatieAdres'] + ", " + item['LocatiePostcode'] + " " + item['LocatiePlaats']}</p><span>(Klik om te openen in Google Maps)</span></div>
        </a></div> : null
        }
        {item["DocumentUploaded"] !== 'true' ?
          <div id="closeFooter" onClick={this.handleClick.bind(this,id)}>
            Onderdeel sluiten
          </div> : null
        }
      </div>
    );

    var fullQuestion = ( 
      <div 
        className="QuestionBox"
        id={id!==undefined?"ID_"+id:null}
        key={id} 
      >
        <div className="ScrollHelper" id={id!==undefined?"helper_ID_"+id:null}></div>
        <div 
          className="HeaderBox"
          onClick={item["DocumentUploaded"] !== 'true' ? this.handleClick.bind(this,id,item["Vraag"] !== undefined ? false : true) : null}
        >
          <div id="titleBox">
            {item["Verplicht"] === "true" ?
                    <h1
                      className={
                        id!==undefined ?
                          this.state.styles["ID_"+item["UUID_Vraag"]]!==undefined ?
                            this.state.styles["ID_"+item["UUID_Vraag"]]['classname']:
                          null:
                        null
                      }
                      
                    >
                      {item["Vraag"] !== undefined ? 
                      item["Vraag"] : "Extra foto's toevoegen"} 
                      <span id="required">*</span>
                    </h1>:
                    <h1>{item["Vraag"] !== undefined ? 
                      item["Vraag"] : "Extra foto's toevoegen"}</h1>}
            {item["DocumentUploaded"] !== 'true' ?
            <FontAwesomeIcon icon={openState ? faCaretDown : faCaretRight } id="icon" />
            : null }
          </div>
        
        {
          !openState ?
          <p id="answerCompact">{
            item["Type"] === "image" || item["Type"] === "canvas" ?
              item["DocumentBase64"] !== "" && item["DocumentUploaded"] === "false" ?
                <Fragment>
                  <FontAwesomeIcon icon={faCheck} id="icon" style={{marginRight:'5px'}} /> 
                  {item["Type"] === "image" ? "Bestand geüpload." : "Handtekening geüpload."}
                </Fragment> : null
            : answer
          }</p>
          : null
        }
          
        </div>
        {dropdownQuestion}
      </div>
    );
    return fullQuestion;
  }

  createPhotoBox(){
    var findUnique = function(id){
      let tempUnique = true;
      Object.keys(savedPhotos).forEach((key) => {
        if(savedPhotos[key]['Omschrijving'].split(" ")[2].toString() === id.toString()){
          tempUnique = false;
        }
      })
      Object.keys(photos).forEach((key) => {
        if(key.split("_")[1].toString() === id.toString()){
          tempUnique = false;
        }
      })
      return tempUnique;
    }

    var photos = this.state.photos;
    var savedPhotos = this.state.extraPhotos;
    var id = 1;
    if(photos !== undefined && savedPhotos !== undefined){
      while(!findUnique(id)){
        id++;
      }
    }

    id = "extraFoto_"+id;

    return  <div>
              {savedPhotos.map(photo => {
                return  <div key={photo["Omschrijving"]} id="imageBox">
                          {photo["UUID_Document"] !== undefined ?
                            <div id="removeButton" onClick={() => this.removeExtraPhoto(photo)}>
                              <FontAwesomeIcon icon={faTrash} id="icon" />
                            </div>
                          : null}
                          <img src={
                            photo["Document"] !== undefined ? photo["Document"] : "https://via.placeholder.com/300.png"
                          } alt={"Extra foto"} className="Image"></img>
                          {/* <ContentEditable
                            id = {photo["UUID_Document"]}
                            html = {photo["Omschrijving"]}
                            onBlur={this.updateExtraPhoto}
                            tagName='article'
                          /> */}
                        </div>
              })}
              {Object.keys(photos).map(photo => {
                return  <div key={photo} id="imageBox">
                          <div id="removeButton" onClick={() => this.removeImage(photo)}>
                            <FontAwesomeIcon icon={faTrash} id="icon" />
                          </div>
                          <img src={photos[photo]} alt={"Extra foto"} className="Image"></img>
                          {/* <ContentEditable
                            id={description_id}
                            html = {this.state.answers[description_id] === undefined ? '' : this.state.answers[description_id]}
                            onBlur={this.handleChange}
                            tagName='article'
                          /> */}
                        </div>
              })}
              <label htmlFor={id} className="ExtraUpload">
                <FontAwesomeIcon icon={faPlus} id="icon" style={{marginRight:'5px'}} />Foto toevoegen
                <input 
                  type="file"
                  id={id}
                  onChange={this.handleChange}
                  accept="image/*"
                />
              </label>
            </div>      
  }

  removeImage(id){
    var photos = this.state.photos;
    delete photos[id]
    this.setState({photos:photos});
  }

  validateQuestions(Vragen, isDisabled = this.state.isDisabled, answers = this.state.answers){
    var completed = true;
    var remaining = 0;
    var remainingList = [];
    var invalidRemaining = 0;
    var invalidRemainingList = [];
    var uuid;

    function validateQuestion(element){
      uuid = element["UUID_Vraag"]
      if(element["Verplicht"] === "true"){
        if(answers[uuid] !== undefined){
          if(answers[uuid].length === 0){
            if(isDisabled && element["Bepalend"] === "true"){
              invalidRemainingList.push(uuid);
              invalidRemaining++
            }
            remainingList.push(uuid);
            remaining++;
          }
        }
        else if(element["Type"] === 'image' || element["Type"] === 'canvas'){
          if(element["DocumentBase64"] === ""){
            if(isDisabled && element["Bepalend"] === "true"){
              invalidRemainingList.push(uuid);
              invalidRemaining++
            }
            remainingList.push(uuid);
            remaining++;
          }
        }
        else{
          if(isDisabled && element["Bepalend"] === "true"){
            invalidRemainingList.push(uuid);
            invalidRemaining++
          }
          remainingList.push(uuid);
          remaining++;
        }
      }
      if(element["SubVragen"] !== undefined && element["SubVragen"].length > 0){
        if((Object.keys(answers).includes(uuid) && (answers[uuid] === "Ja" || answers[uuid] === "Anders...")) || element["Conditioneel"] !== "true"){
          element["SubVragen"].forEach(sub => {
            validateQuestion(sub)
          });
        }
      }
    }
      Vragen.forEach(element => {
        validateQuestion(element)
      });

    if(isDisabled){
      remaining = invalidRemaining;
      remainingList = invalidRemainingList;
    }
    if(remaining !== 0 ){
      completed = false;
    }

    this.setState({completed:completed});
    this.setState({remaining:remaining});
    this.setState({remainingUUIDs:remainingList});
  }   
  
  storeSignature(sig, id){
    let answers = this.state.answers;
    answers[id] = sig;
    this.getTimeStamp(id);
    this.setState({answers:answers});
    this.validateQuestions(this.state.data.Vragen);
    console.log(sig)
  }

  clearSignature(sig, id){
    if(sig === null){
      let data = this.state.data;
      console.log(data["Vragen"]);
      let item = data["Vragen"].find(x => x.UUID_Vraag === id);
      item["DocumentBase64"] = "";
      this.setState({data:data})
    }
    else{
      sig.clear();
      let answers = this.state.answers;
      answers[id] = '';
      this.setState({answers:answers});
    }
    this.validateQuestions(this.state.data.Vragen);
  }

  getLocation(id){
    let answers = this.state.answers;
    let longitude, latitude = '';
    if(navigator.geolocation){
      navigator.geolocation.getCurrentPosition((pos) => {
        longitude = pos.coords.longitude;
        latitude = pos.coords.latitude;
        answers[id+"_location"] = {
          "Longitude" : longitude,
          "Latitude" : latitude
        }
        this.validateQuestions(this.state.data.Vragen);
        this.setState({answers:answers});
      },
      (err) => {
        console.log(err);
        answers[id] = undefined;

        this.validateQuestions(this.state.data.Vragen);
        this.setState({answers:answers});

        this.setState({modal: 'no-location'})
      });
    }
  }
 
  getTimeStamp(id){
    let answers = this.state.answers;
    answers[id+"_timestamp"] = new Date().toLocaleString('nl-NL');
    this.setState({answers: answers});
  }

  removeExtraPhoto(photo){
    var extraPhotos = this.state.extraPhotos;
    var updatePhotos = this.state.updatePhotos;
    var index = extraPhotos.findIndex(x => x["UUID_Document"]===photo["UUID_Document"])
    var photo_UUID = extraPhotos[index]["UUID_Document"];
    updatePhotos["remove"].push(photo_UUID);
    extraPhotos.splice(index,1);
    if(updatePhotos["description"].findIndex(x => x["UUID_Document"]===photo["UUID_Document"]) !== -1){
      updatePhotos["description"].splice(updatePhotos["description"].findIndex(x => x["UUID_Document"]===photo["UUID_Document"]),1);
    }
    this.setState({extraPhotos:extraPhotos});
    this.setState({updatePhotos:updatePhotos});
  }

  updateExtraPhoto(event){
    var extraPhotos = this.state.extraPhotos;
    var updatePhotos = this.state.updatePhotos;
    var index = extraPhotos.findIndex(x => x["UUID_Document"]===event.target.id);
    extraPhotos[index]["Omschrijving"] = event.target.innerText;
    if(updatePhotos["description"].findIndex(x => x["UUID_Document"]===event.target.id) !== -1){
      updatePhotos["description"].splice(updatePhotos["description"].findIndex(x => x["UUID_Document"]===event.target.id));
    }
    updatePhotos["description"].push({
      "UUID_Document": event.target.id,
      "Omschrijving": event.target.innerText
    });
    this.setState({extraPhotos:extraPhotos});
    this.setState({updatePhotos:updatePhotos});
  }

  flashQuestion(){
    var question = "ID_"+this.state.remainingUUIDs[0];
    this.unfoldQuestion(question)
    var styles = this.state.styles;
    if(styles[question]['classname'] === ''){
      setTimeout(
        function() {
          styles[question]['classname'] = '';
          this.setState({styles:styles});
        }
        .bind(this),
        1500
      );
  
      styles[question]['classname'] = 'highlighted';
      this.setState({styles:styles});
    }
  }

  unfoldQuestion(id){
    let clicked = this.state.clicked;
    let styles = this.state.styles;
    setClicked(id);

    function setClicked(id){
      let stripped_id = id.replace("ID_",'');
      if(!clicked[stripped_id] || clicked[stripped_id] === undefined){
        clicked[stripped_id] = true
      }
      id = styles[id]['parent'];
      if(styles[id] !== undefined){
        setClicked(id);
      }
    }
    
    this.setState({clicked: clicked});
  }

  foldQuestions(){
    let clicked = this.state.clicked;
    Object.keys(clicked).forEach((item) => {
      clicked[item] = false;
    });
    this.setState({clicked:clicked})
  }

  async submitForm(type, mode='all'){

    let log = this.state.log;
    log.push({time: new Date(), msg: "Starting submit"});
    this.setState({log:log});

    if((this.state.completed || type === 'opslaan') && !this.state.saving && !this.state.submitting){
      var json;
      var answers = this.state.answers;

      if(!this.state.isDisabled){
        var Vragen = this.state.data["Vragen"];
        var photos = this.state.photos;
        json = {
          "Antwoorden": [],
          "Fotos": [],
          "Update": this.state.updatePhotos
        };
  
        const submitSubQuestions = function(entry){
          var answer_sub_temp = undefined;
          var conditionalSubs = false;
          if((entry["Conditioneel"] === "false") || 
          (entry["Conditioneel"] === "true" && (answers[entry["UUID_Vraag"]] === "Ja" || answers[entry["UUID_Vraag"]] === "Anders...")) ||
          (entry["SubVragen"].length === 2 && (entry["SubVragen"][0]["Conditioneel"].toLowerCase() === "ja" || entry["SubVragen"][0]["Conditioneel"].toLowerCase() === "nee"))){
            if(entry["SubVragen"].length === 2 && (entry["SubVragen"][0]["Conditioneel"].toLowerCase() === "ja" || entry["SubVragen"][0]["Conditioneel"].toLowerCase() === "nee")){
              conditionalSubs = true
            }
            entry["SubVragen"].forEach(sub => {
              var uuid_sub = sub["UUID_Vraag"]
              var answer_sub = answers[uuid_sub];
              if(sub["Type"] === 'checkbox' && answer_sub !== undefined){
                answer_sub_temp = answer_sub.join(", ");
              }
              else{
                answer_sub_temp = answer_sub;
              }
              if(sub["Antwoord"] !== answer_sub_temp){
                if(answer_sub !== undefined){
                  if(!conditionalSubs || (conditionalSubs && sub["Conditioneel"].toLowerCase() === answers[entry["UUID_Vraag"]].toLowerCase())){
                    let log = this.state.log;
                    log.push({time: new Date(), msg: "Added to response JSON: " + uuid_sub});
                    this.setState({log:log});
                    json["Antwoorden"].push({
                      "UUID_Vraag" : uuid_sub,
                      "Antwoord" : answer_sub
                    });
                  }
                }
              }
              if(sub["SubVragen"] !== undefined && sub["SubVragen"].length > 0){
                submitSubQuestions(sub)
              }
            });
          }
          else if(entry["Conditioneel"] === "true" && (answers[entry["UUID_Vraag"]] !== "Ja" || answers[entry["UUID_Vraag"]] !== "Anders...")){
            entry["SubVragen"].forEach(sub => {
              var uuid_sub = sub["UUID_Vraag"]
              var answer_sub = answers[uuid_sub];
              if(answer_sub !== "" && answer_sub !== undefined){
                json["Antwoorden"].push({
                  "UUID_Vraag" : sub["UUID_Vraag"],
                  "Antwoord" : ""
                });
              }
            });
          }
        }.bind(this);
  

        if(mode === 'all'){
          Vragen.forEach(entry => {
            var uuid = entry["UUID_Vraag"];
            var answer = answers[uuid];
            var answer_temp = undefined;
            if(entry["Type"] === 'checkbox' && answer !== undefined){
              answer_temp = answer.join(", ");
            }
            else{
              answer_temp = answer;
            }

            if(entry["Vraag"] === "Vermoedelijke indeling?" &&
            this.state.answers[Vragen[Vragen.findIndex(x => x["Vraag"]==="Binnen geweest?")]["UUID_Vraag"]] !== "Nee"){
              answer = "";
              answer_temp = answer;
            }

              if(entry["Antwoord"] !== answer_temp){
                if(answer !== undefined){
                  var objectToPush = {
                    "UUID_Vraag" : uuid,
                    "Antwoord" : answer
                  }
    
                  if(answers[uuid+"_location"] !== undefined){
                    objectToPush["Locatie"] = answers[uuid+"_location"];
                  }
                  if(answers[uuid+"_timestamp"] !== undefined){
                    objectToPush["Tijdstempel"] = answers[uuid+"_timestamp"];
                  }
                  let log = this.state.log;
                  log.push({time: new Date(), msg: "Added to response JSON: " + objectToPush['UUID_Vraag']});
                  this.setState({log:log});
                  json["Antwoorden"].push(objectToPush);
                }
              }
            
  
            if(entry["SubVragen"].length > 0){
              submitSubQuestions(entry)
            }
          });
        }

        Object.keys(photos).forEach(photo => {
          let log = this.state.log;
          log.push({time: new Date(), msg: "Added to image JSON: Extra foto " + photo.split("_")[1]});
          this.setState({log:log});
          json["Fotos"].push({
            "UUID": photo,
            "Foto": photos[photo],
            "Omschrijving": 'Extra foto ' + photo.split("_")[1]
          });
        });
      }
      else{
        json = {
          "Antwoorden": [],
          "Fotos": [],
          "Update": {
            "description": [],
            "remove":[]
          }
        };
        var disabledList = this.state.disabledList;
        
        Object.keys(disabledList).forEach(key => {
          if(answers[key] !== undefined){
            json["Antwoorden"].push({
              "UUID_Vraag": key,
              "Antwoord": answers[key]
            })
          }
        });
      }

      let log = this.state.log;
      log.push({time: new Date(), msg: "Posting " + json['Antwoorden'].length + " answers and " + json['Fotos'].length + " photos"}); 
      // log.push({time: new Date(), msg: "Deleting " + json['Update']['Remove'].length + " photos"});
      this.setState({log:log});

      if(type === 'opslaan'){
        this.setState({saving: true});
      }else {
        this.setState({submitting: true});
      }

      await PostData(`vragenlijsten/${this.state.data["UUID_Formulier"]}`, {answers: json, type: type})
        .then((data) => {
          let log = this.state.log;
          log.push({time: new Date(), msg: "Succesfully posted"});

          let extraPhotos = this.state.extraPhotos;
          let photos = this.state.photos;

          log.push({time: new Date(), msg: "Beginning to move photos"});
          // log.push({time: new Date(), msg: photos});

          Object.keys(photos).forEach((key) => {
            let photo = {
              Document: photos[key],
              Omschrijving: "Extra foto " + key.split("_")[1]
            }
            extraPhotos.push(photo);
          })

          this.setState({photos: []});
          this.setState({updatePhotos: {
            description: [],
            remove:[]
          }});

          this.setState({log:log});
          this.setState({extraPhotos:extraPhotos});



          this.setState({submitting: false});
          if(type !== 'opslaan'){
            this.setState({modal: type})
          }
          else{
            if(mode === 'all'){
              Object.keys(extraPhotos).forEach((key) => {
                if(extraPhotos[key]['UUID_Document'] === undefined){
                  window.location.reload();
                }
              })
            }

            this.setState({saving: 'done'});
            setTimeout(
              function() {
                this.setState({saving: false});
              }
              .bind(this),
              2000
            );
          }
        })
        .catch((err) => {
            let log = this.state.log;
            log.push({time: new Date(), msg: "Error in post: " + err});
            this.setState({log:log});

            this.setState({saving: false});
            this.setState({submitting: false});
            this.setState({modal: type+"_error"})
        })
    }
  }

  render(){
    try{
      if(!this.state.finishedLoading){
        return <Loading />
      }
      else{
        if(this.state.saving){
          // return <Loading message={"Bezig met opslaan..."} />
        }
        if(this.state.submitting){
          return <Loading message={"Bezig met verzenden..."} />
        }
        var data = this.state.data;
        var Makelaar = Object(data.Makelaar);
        var Objectgegevens = Object(data.Objectgegevens);
        var Vragen = Array(data.Vragen);
        
        return (
          <div className="FormContainer Container">
            <div className="TopBarControls">
              <div className="TopBarButton">
                <Link to={"/dashboard"} id="back">
                  <FontAwesomeIcon icon={faArrowLeft} id="icon" />
                  Terug
                </Link>
              </div>
              <div className="TopBarButton">
                <p id="address" onClick={() => this.setState({devLogs: !this.state.devLogs})}>{Objectgegevens["Adres"]} <span> | {Objectgegevens["Postcode"]}</span></p>
              </div>
            </div>
            <Popup
              modal
              lockScroll={this.state.lockScroll}
              open={this.state.modal === 'closed' ? false : true}
              onClose={() => {
                if(this.state.modal === 'verzenden'){
                  window.location.href="/dashboard";
                }
                else{
                  this.setState({modal: 'closed'});
                }
              }}
            >
              {close => (
                <div className="modal">
                  <div className="content">
                    {
                    this.state.modal === 'verzenden' ?
                    <img src="../Images/check.svg" alt="success"></img> :
                    this.state.modal === 'opslaan_error' || this.state.modal === 'verzenden_error' ?
                    <img src="../Images/cross.svg" alt="error"></img> :
                    this.state.modal === 'afronden' ?
                    <img src="../Images/exclamation.svg" alt="warning"></img> :
                    <img src="../Images/cross.svg" alt="error"></img>
                    }
                    {
                    this.state.modal === 'verzenden' ?
                    <p>De vragenlijst is verzonden.</p> :
                    this.state.modal === 'opslaan_error' ?
                    <p>De vragenlijst kon niet worden opgeslagen. Probeer het opnieuw.</p> :
                    this.state.modal === 'verzenden_error' ?
                    <p>De vragenlijst kon niet worden verzonden. Probeer het opnieuw.</p> :
                    this.state.modal === 'afronden' ?
                    <p>U mag deze vragenlijst niet invullen omdat u betrokken bent geweest bij de debiteur of het registergoed.<br/>Klik op <span style={{fontWeight:'bold'}}>verzenden</span>.</p> :
                    this.state.modal === 'no-location' ?
                    <p>U dient deze applicatie toegang te geven tot uw <span style={{fontWeight:'bold'}}>locatie</span> om deze foto te kunnen uploaden.</p> :
                    <p>Er is een onbekende fout opgetreden. Probeer het opnieuw</p>
                    }
                  </div>
                  <div className="actions">
                    <button
                      className="button"
                      onClick={() => {
                        close();
                      }}
                    >
                      {this.state.modal === 'verzenden' ?
                      'Afronden' :
                      'Sluiten'
                      }
                    </button>
                  </div>
                </div>
              )}
            </Popup>
            <h1 id="subTitle">Makelaar</h1>
            <table className="InfoTable">
              <tbody>
                {Object.keys(Makelaar).map(element => {
                  if(element !== "UUID_Relatie"){
                    if(Makelaar[element].length > 0){
                      return (
                        <tr key={Makelaar["UUID_Relatie"]+"_"+element}>
                          <td className="Keys">{
                            element === "NaamContact" ? "Naam" : element === "Telefoonnummer" ? "Telefoon" : element
                          }</td>
                          <td>{Makelaar[element]}</td>
                        </tr>
                      )}
                      else{
                        return null;
                      }
                    }
                    else{
                      return null;
                    }
                  })}
                </tbody>
              </table>
              <h1 id="subTitle">Object</h1>
              <table className="InfoTable">
              <tbody>
                {Object.keys(Objectgegevens).map(element => {
                  if(element !== "UUID_Object"){
                    if(Objectgegevens[element].length > 0){
                      return (
                        <tr key={Objectgegevens["UUID_Object"]+"_"+element}>
                          <td className="Keys">{element}</td>
                          <td>{Objectgegevens[element]}</td>
                        </tr>
                      )}
                      else{
                        return null;
                      }
                    }
                    else{
                      return null;
                    }
                  })}
                </tbody>
              </table>
            {Object.keys(Vragen).map((element, i) => {
                  return (
                    Vragen[element] ?
                    <div key={i}>
                      { 
                        Vragen[`${i}`].map((key) => {
                          return  <div 
                                    key={key["UUID_Vraag"]}
                                    
                                    className={
                                      this.state.isDisabled ? 
                                      key["Bepalend"] !== "true" ?
                                      'DisabledQuestion' : null : null
                                    }
                                  >    
                                  {key["Header"] !== "" ?
                                  <h1 id="subTitle">{key["Header"]}</h1> : null
                                  }
                                  {key["Vraag"] === "Extra foto 1" ?
                                    this.assembleQuestion("photo") :
                                    key["Vraag"] === "Vermoedelijke indeling?" &&
                                    this.state.answers[Vragen[0][Vragen[0].findIndex(x => x["Vraag"]==="Binnen geweest?")]["UUID_Vraag"]] !== "Nee" ?
                                    null
                                    :
                                    this.assembleQuestion(key)
                                  }
                                </div>
                        })}
                    </div> :
                    null
                  );                    
              })}
              <div className="FooterInfo">
                {
                this.state.completed ? <p>klik op <span style={{fontWeight:'bold'}}>Verzenden</span> om de vragenlijst in te leveren.<br/>Klik op <span style={{fontWeight:'bold'}}>Opslaan</span> als je de vragenlijst later wil afronden.</p> : 
                <p>Nog niet alle verplichte vragen zijn ingevuld.<br/>Klik op <span style={{fontWeight:'bold'}}>{this.state.remaining} resterend</span> om naar de eerstvolgende verplichte vraag te gaan.</p>
                }
              </div>
            <div className="Footer">
              <div><p onClick={() => this.foldQuestions()} id="save">Sluit vragen</p></div>
              <div>
              <p id="save" style={{opacity: this.state.saving !== false ? '0.5' : '1.0'}} onClick={() => this.submitForm('opslaan')}>
                  {this.state.saving !== true ? <FontAwesomeIcon icon={faCheck} id="icon" /> : null }
                  {this.state.saving === true ? "Bezig..." : this.state.saving === 'done' ? "Opgeslagen" : "Opslaan"}
              </p>
              </div>

              {this.state.remaining !== undefined && this.state.remainingUUIDs.length > 0 ?
                  <ScrollIntoView style={{marginRight: '4px'}} onClick={() => this.flashQuestion()} alignToTop={true} scrollOptions={{behavior: 'smooth', block: 'center'}} selector={"#helper_ID_"+this.state.remainingUUIDs[0]}>
                    <button>{this.state.remaining + " resterend"}</button>
                  </ScrollIntoView>:
                  <div style={{marginRight: '4px'}} ><button style={{opacity: this.state.saving !== false ? '0.5' : '1.0'}} onClick={() => this.submitForm('verzenden')}>{'Verzenden'}</button></div>
                }
                <p className="requiredText"><span style={{color: 'var(--accent-orange)', fontWeight: 'bold'}}>*</span> = verplicht</p>
            </div>
            {/* {this.state.devLogs ? this.state.log.map(element => {
              let timestamp = element['time'];
              var time = timestamp.getHours() + ":" + timestamp.getMinutes() + ":" + timestamp.getSeconds();
            return (
              <p key={element['msg']}>{time} | {element['msg']}<br/></p>
            )}) : null} */}
          </div>
        );
      }
    }
    catch(err){
      window.location.href="/dashboard";
    }
  }
}
  
export default Form;