import { React } from 'react';
import Nav from './Nav';
import Routes from './Routes';

function App() {
    var path = window.location.pathname;
    var showNav;
    if(path === '/' ||
    path === '/forgot' ||
    path.includes('/reset')){
        showNav = false;
    }
    else{
        showNav = true;
    }
    return (
        <div id="innerContainer">
            {showNav ? <Nav /> : null}
            <Routes />
        </div>
    )
}

export default App