import React from "react";
import '../Stylesheets/Login.css';
import { Link } from "react-router-dom";
import { PostData } from '../Javascripts/API'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'

class Forgot extends React.Component {

  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.state = {
      email: '',
      errorMsg: ''
    }
  }

  async handleSubmit(){
    this.setState({errorMsg: ''})
    var email = this.state.email;

    await PostData('reset', {email: email})
    .then((data) => {
        if (data.message !== 'Authenticated') {
          if(data.message !== undefined){
            this.setState({errorMsg:data.message})
          }
        } else {
          this.props.history.push('/dashboard');
        }
    })
    .catch((err) => {
      this.setState({errorMsg:'Er is een fout opgetreden: ' + err})
    })

  }

  handleChange(event) {
    this.setState({email: event.target.value});
  }

    render(){
        return (
            <div className="LoginContainer">
                <img src={"../Images/logo_small.png"} alt={"K&H Logo"}></img>
                <h1>Wachtwoord vergeten</h1>
                {this.state.errorMsg !== '' ? <p id="error"><span id="errorIcon"><FontAwesomeIcon icon={faExclamationTriangle}/></span>{this.state.errorMsg}</p> : ''}
              <form>
                  <input 
                    type={"text"} 
                    placeholder={"E-mailadres"} 
                    required
                    onChange={this.handleChange}
                  />
                  <p id="ForgotPSW">U ontvangt een e-mail met instructies om uw wachtwoord opnieuw in te stellen.</p>
                  <button className={"LoginButton"} type={"reset"} onClick={
                      () => this.handleSubmit()
                    }>Verzenden</button>
                    <Link to="/" className="link" id="BackToLoginLink">Terug naar inloggen</Link>
              </form>
            </div>
        );
    }
  }
  
  export default Forgot;
  