import '../Stylesheets/Global.css';
import '../Stylesheets/Loading.css';

function Loading(props) {
    var message = props.message
    if(message === undefined){
        message = "Bezig met laden..."
    }
    return <div id="loadingBackground">
        <div className="loadingDiv">
            <img id="loadingImage" src="Images/logo_spin.gif" alt={"K&H Logo GIF"}></img>
            <h1>{message}</h1>
        </div>
    </div>
}

export default Loading