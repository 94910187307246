import React from "react";
import '../Stylesheets/Dashboard.css';
import { Link } from "react-router-dom";
import Loading from "./Loading";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome } from '@fortawesome/free-solid-svg-icons'
import { GetData } from '../Javascripts/API'

class Dashboard extends React.Component {
    _isMounted = false;
    constructor (props) {
        super(props);
        this.state = {
            objects: [],
            open: [],
            saved: []
        }
    }

    async componentDidMount() {
        this._isMounted = true;
        var data = await GetData('vragenlijsten').catch((err) => {
            var objectsError = [...this.state.objects];
            objectsError.push({message:"Er is een fout opgetreden: " + err});
        });
        var objectsArray = [...this.state.objects]
        objectsArray.push(data)
        var openArray = objectsArray[0]['open']
        var savedArray = objectsArray[0]['saved']
        if(this._isMounted){
            this.setState({open: openArray})
            this.setState({saved: savedArray})
            this.setState({objects: objectsArray})
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render(){
        if (this.state.objects.length === 0) {
            return <Loading />

        } else {
            const objects = this.state.objects
            const open = this.state.open
            const saved = this.state.saved

            if(objects[0]["message"] !== undefined){
                return (
                    <div className="DashboardContainer Container">
                        <p id="dashError">{objects[0]["message"]}</p>
                    </div>
                )
            }
            else {
                return (
                    <div className="DashboardContainer Container">
                        { open.length > 0 ? 
                        <div className="dashboardOpen">
                            <h1 id="dashboardSubtitle">Open vragenlijsten</h1>
                        {Object.keys(open).map(key => {
                            return <Link to={{
                                    pathname: "/form",
                                    state: { id: open[key].UUID_Vragenlijst }
                                    }} 
                                    className="Item"
                                    key={key}
                                    style={{display:'flex',justifyContent:'space-between'}}
                                >
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    <FontAwesomeIcon icon={faHome} id="icon" />
                                    <p>
                                        {open[key]["ObjectGegevens"]["Adres"]}
                                    </p>
                                </div>
                            </Link>
                        })}
                        </div>
                        : null }
                        { saved.length > 0 ? 
                            <div className="dashboardSaved">
                            
                            <h1 id="dashboardSubtitle">Opgeslagen vragenlijsten</h1>

                                {Object.keys(saved).map(key => {
                                    let dateArray = saved[key]["Tijdstempel van wijziging"].split('/');
                                    let formattedDate = dateArray[1] + "/" + dateArray[0] + "/" + dateArray[2].split(' ')[0] + " | " + dateArray[2].split(' ')[1];
                                    return <Link to={{
                                            pathname: "/form",
                                            state: { id: saved[key].UUID_Vragenlijst }
                                            }} 
                                            className="Item"
                                            key={key}
                                            style={{display:'flex', justifyContent:'space-between', position:'relative'}}
                                        >
                                        <div style={{display: 'flex', alignItems: 'center'}}>
                                            <FontAwesomeIcon icon={faHome} id="icon" />
                                            <div style={{display: 'flex', justifyContent: 'flex-start', flexDirection: 'column'}}>
                                                <p>
                                                    {saved[key]["ObjectGegevens"]["Adres"]}
                                                </p>
                                                <p style={{fontSize:'12px'}}>
                                                    Gewijzigd op: {formattedDate}
                                                </p>
                                            </div>
                                        </div>
                                    </Link>
                                })}
                            </div>
                        : null}
                    </div>
                );
            }
        }
    }
}   
export default Dashboard;  