import React from "react";
import '../Stylesheets/Nav.css';

class Nav extends React.Component {

    constructor(props) {
        super(props);
        this.signOut = this.signOut.bind(this);
        this.location = '';
    }

    signOut() {
        window.location.href = "/";
    }

    render(){
        return (
            <div className="NavContainer">
                <img src={"../Images/logo_small.png"} alt={"K&H Logo"}></img>
                <p>{"Kinneging & Heijer"}</p>
                <p onClick={() => this.signOut()} id="signOutButton">Log uit</p>
            </div>
        );
    }
}
  
export default Nav;  