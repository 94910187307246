import React from "react";
import '../Stylesheets/NotFound.css';

class NotFound extends React.Component {

    render(){
        return (
            <div className="NotFoundContainer Container">
              <img src="Images/logo_small.png" alt={"K&H Logo"}></img>
              <p>De pagina is niet gevonden.</p>
            </div>
        );
    }
  }
  
  export default NotFound;
  