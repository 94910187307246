import React from "react";
import '../Stylesheets/Login.css';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'
import { PostData } from '../Javascripts/API'

class Login extends React.Component {

    constructor(props) {
        super(props);
        this.handleLogin = this.handleLogin.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.togglePassword = this.togglePassword.bind(this);
    }

    state = {
        username:'',
        password: '',
        passwordHidden: true,
        errorMsg: ''
    }

    handleChange(event) {
        var target = event.target.id;
        var value = event.target.value;
        if (target === "loginUsername") {
            this.setState({ username: value });
        }
        else if (target === "loginPassword") {
            this.setState({ password: value });
        }
    }

    async handleLogin(event) {
        event.preventDefault();

        this.setState({errorMsg: ''})

        var loginUsername = this.state.username;
        var loginPassword = this.state.password;

        await PostData('login', {loginUsername: loginUsername, loginPassword: loginPassword})
        .then((data) => {
            if (data.message !== 'Authenticated') {
                if(data.message !== undefined){
                    this.setState({errorMsg:data.message})
                }
            } else {
                window.location.href="/dashboard";
            }
        })
        .catch((err) => {
            this.setState({errorMsg:'Er is een fout opgetreden: ' + err})
        })
    }

    togglePassword() {
        if(this.state.passwordHidden){
            this.setState({passwordHidden: false})
        }
        else{
            this.setState({passwordHidden: true})
        }
    }

    render(){
        return (
            <div className="LoginContainer">
                <img src={"../Images/logo_small.png"} alt={"K&H Logo"}></img>
                <h1>Inloggen</h1>
                {this.state.errorMsg !== '' ? <p id="error"><span id="errorIcon"><FontAwesomeIcon icon={faExclamationTriangle}/></span>{this.state.errorMsg}</p> : ''}
              <form onSubmit={this.handleLogin}>
                    <span id="user">
                        <input 
                            type={"text"} 
                            id="loginUsername" 
                            value={this.state.username}
                            onChange={this.handleChange} 
                            placeholder={"Gebruikersnaam"} 
                            required
                            autoComplete="on"
                        />
                    </span>
                    <span id="psw">
                        <input 
                            type={this.state.passwordHidden ? "password" : "text"} 
                            id="loginPassword" 
                            value={this.state.password}
                            onChange={this.handleChange} 
                            placeholder={"Wachtwoord"} 
                            required
                            autoComplete="on"
                        />
                        <FontAwesomeIcon icon={this.state.passwordHidden ? faEye : faEyeSlash} id="showPSW" onClick={this.togglePassword}/>
                    </span>
                  <Link to="/forgot" className="link" id="ForgotPSWLink">Ik ben mijn wachtwoord vergeten</Link>
                  <button className="LoginButton">Inloggen</button>
              </form>
            </div>
        );
    }
  }
  
  export default Login;
  